import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  FunctionField,
} from "admin-on-rest";

export const Staff = (props) => {
  return (
    <List {...props} perPage={5}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" label="name" />
        <FunctionField
          label="member image"
          render={(record) => <img src={record.image} style={{ width: 100 }} />}
        />
        <TextField source="position" label="position" />
        <TextField source="bio" label="bio" />
        <TextField source="email" label="email" />
        <TextField source="linkedinLink" label="linkedin Link" />
        <TextField source="orcidLink" label="orcid Link" />

        <EditButton />
      </Datagrid>
    </List>
  );
};