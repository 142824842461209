import * as React from "react";
import { useFormik } from "formik";
import axios from "axios";
import { useHistory } from "react-router-dom";
import styles from "./analyticalBulletin.module.css";
// ------------------------------------------------------------------------------------
// import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MaterialTextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";

import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Create } from "admin-on-rest";

const validate = (values) => {
  const errors = {};

  if (!values.image) {
    errors.image = "image is required!";
  }

  return errors;
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: "none",
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const BulletinCreate = (props) => {
  const [previewImageUrls, setPreviewImageUrls] = React.useState({});
  const [value, setValue] = React.useState("one");
  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const history = useHistory({
    forceRefresh: true,
  });
  const formData = new FormData();
  const formik = useFormik({
    initialValues: {
      image: "",
      linkTo: "",
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate,
    onSubmit: async (values) => {
      formData.append("image", values.image);
      formData.append("linkTo", values.linkTo);
     
      for (var pair of formData.entries()) {
        //console.log(pair[0] + " - " + pair[1]);
      }
      const response = await axios({
        method: "POST",
        baseURL: "https://api.cccs.am/api/v1/bulletin",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
      });
      history.push("/#/bulletin");
      window.location.reload();
    },
  });

  return (
    <Create {...props}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.formContainer}>
          <div className={styles.imageAndEmailContainer}>
            <input
              accept="image/*"
              type="file"
              name="image"
              className={classes.input}
              onChange={(e) => {
                setPreviewImageUrls({
                  ...previewImageUrls,
                  image: URL.createObjectURL(e.target.files[0]),
                });
                formik.setFieldValue("image", e.currentTarget.files[0]);
              }}
              id="upload-main-image"
            />
            <label htmlFor="upload-main-image">
              <div className={styles.inputAndPreviewContainer}>
                <div className={styles.mainImageInputContainer}>
                  <Button
                    variant="outlined"
                    component="span"
                    style={{
                      width: "300px",
                      backgroundColor: "#00BCD7",
                      color: "white",
                      fontWeight: "700",
                    }}
                  >
                    Upload bulletin image
                  </Button>
                  <IconButton style={{ color: "#00BCD7" }} component="span">
                    <PhotoCamera />
                  </IconButton>
                </div>
                <img
                  src={previewImageUrls.image}
                  style={{ maxWidth: "200px" }}
                />
              </div>
            </label>
            {formik.errors.image ? (
              <div className={styles.errorContainer}>{formik.errors.image}</div>
            ) : null}
            <MaterialTextField
              style={{ marginBottom: "15px", width: "100%" }}
              id="linkTo"
              variant="outlined"
              label="Link To"
              name="linkTo"
              focused
              value={formik.values.linkTo}
              onChange={formik.handleChange}
            />
            {formik.errors.email ? (
              <div className={styles.errorContainer}>{formik.errors.email}</div>
            ) : null}
          </div>
          
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            style={{ backgroundColor: "#00BCD4", width: "15%" }}
          >
            Create
          </Button>
        </div>
      </form>
    </Create>
  );
};

export default BulletinCreate;
