import * as React from "react";
import {
  List,
  Filter,
  Edit,
  Create,
  Datagrid,
  TextField,
  EditButton,
  FunctionField,
  TextInput,
} from "admin-on-rest";

export const ScientificCouncil = (props) => {
  return (
    <List {...props} perPage={5}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" label="name" />
        <FunctionField
          label="member image"
          render={(record) => <img src={record.image} style={{ width: 100 }} />}
        />
        <TextField source="position" label="position" />
        <TextField source="bio" label="bio" />
        <TextField source="email" label="email" />

        <EditButton />
      </Datagrid>
    </List>
  );
};
