import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  FunctionField,
} from "admin-on-rest";

export const Partners = (props) => {
  return (
    <List {...props} perPage={5}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <FunctionField
          label="image"
          render={(record) => <img src={record.image} style={{ width: 100 }} />}
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};
