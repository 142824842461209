import * as React from "react";
import { useFormik } from "formik";
import axios from "axios";
import { useHistory } from "react-router-dom";
import styles from "./Theories.module.css";
import { Create } from "admin-on-rest";

// ------------------------------------------------------------------------------------
// import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MaterialTextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Editor from "../news/Editor";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
  input: {
    display: "none",
  },
}));

const validate = (values) => {
  const errors = {};

  if (!values.mainImage) {
    errors.mainImage = "Main image is required!";
  }
  if (!values.translations.en.title) {
    errors.titleEn = "Title is required!";
  }
  if (!values.translations.hy.title) {
    errors.titleHy = "Title is required!";
  }
  return errors;
};

const TheoriesCreate = (props) => {
  const history = useHistory({
    forceRefresh: true,
  });
  const [previewImageUrls, setPreviewImageUrls] = React.useState({});
  const classes = useStyles();
  const [markedTextHy, setMarkedTextHy] = React.useState("");
  const [markedTextEn, setMarkedTextEn] = React.useState("");
  const [value, setValue] = React.useState("one");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const formData = new FormData();
  const formik = useFormik({
    initialValues: {
      mainImage: "",
      translations: {
        hy: {
          title: "",
          markedText: "",
        },
        en: {
          title: "",
          markedText: "",
        },
      },
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate,
    onSubmit: async (values) => {
      formData.append("mainImage", values.mainImage);
      formData.append("translations[en][title]", values.translations.en.title);
      formData.append("translations[en][markedText]", markedTextEn);
      formData.append("translations[hy][title]", values.translations.hy.title);
      formData.append("translations[hy][markedText]", markedTextHy);
      const response = await axios({
        method: "POST",
        baseURL: "https://api.cccs.am/api/v1/theories",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
      });
      history.push("/#/theories");
      window.location.reload();
    },
  });
  const updateMarkedTextHy = (editorData) => {
    const data = editorData.getData();
    setMarkedTextHy(data);
  };
  const updateMarkedTextEn = (editorData) => {
    const data = editorData.getData();
    setMarkedTextEn(data);
  };
  return (
    <Create {...props}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.formWrapper}>
          <div className={styles.formContainer}>
            <input
              accept="image/*"
              type="file"
              name="mainImage"
              className={classes.input}
              onChange={(e) => {
                setPreviewImageUrls({
                  ...previewImageUrls,
                  image: URL.createObjectURL(e.target.files[0]),
                });
                formik.setFieldValue("mainImage", e.currentTarget.files[0]);
              }}
              id="upload-main-image"
            />
            <label htmlFor="upload-main-image" className={styles.labelForImage}>
              <div className={styles.inputAndPreviewContainer}>
                <div className={styles.mainImageInputContainer}>
                  <Button
                    variant="outlined"
                    component="span"
                    style={{
                      width: "200px",
                      backgroundColor: "#00BCD7",
                      color: "white",
                      fontWeight: "700",
                    }}
                  >
                    Upload main image
                  </Button>
                  <IconButton style={{ color: "#00BCD7" }} component="span">
                    <PhotoCamera />
                  </IconButton>
                </div>
                <img
                  src={previewImageUrls.image}
                  style={{ maxWidth: "200px" }}
                />
              </div>
            </label>
            {formik.errors.mainImage ? (
              <div className={styles.errorContainer}>
                {formik.errors.mainImage}
              </div>
            ) : null}
            <AppBar position="static" style={{ backgroundColor: "#00BCD7" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="wrapped label tabs example"
              >
                <Tab
                  value="one"
                  label="Armenian"
                  wrapped
                  {...a11yProps("one")}
                />
                <Tab value="two" label="English" {...a11yProps("two")} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index="one">
              <div className={styles.translationContainer}>
                <div>
                  <MaterialTextField
                    style={{ marginBottom: "15px", width: "30%" }}
                    id="titleHy"
                    variant="outlined"
                    label="Title"
                    name="translations.hy.title"
                    value={formik.values.translations.hy.title}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.titleHy ? (
                    <div className={styles.errorContainer}>
                      {formik.errors.titleHy}
                    </div>
                  ) : null}
                </div>
                <Editor
                  updateOnChange={updateMarkedTextHy}
                  markedData={markedTextHy}
                ></Editor>
              </div>
            </TabPanel>
            <TabPanel value={value} index="two">
              <div className={styles.translationContainer}>
                <div>
                  <MaterialTextField
                    style={{ marginBottom: "15px", width: "30%" }}
                    id="titleEn"
                    variant="outlined"
                    label="Title"
                    name="translations.en.title"
                    value={formik.values.translations.en.title}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.titleEn ? (
                    <div className={styles.errorContainer}>
                      {formik.errors.titleEn}
                    </div>
                  ) : null}
                </div>
                <Editor
                  updateOnChange={updateMarkedTextEn}
                  markedData={markedTextEn}
                ></Editor>
              </div>
            </TabPanel>
            <div className={styles.buttonContainer}>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                style={{ backgroundColor: "#00BCD4", width: "15%" }}
              >
                Create
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Create>
  );
};
export default TheoriesCreate;
