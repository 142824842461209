// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".History_translationContainer__38cXt {\n  display: flex;\n  flex-direction: column;\n  grid-gap: 40px;\n  gap: 40px;\n}\n\n.History_mainImageInputContainer__3sjcO {\n  display: flex;\n  flex-direction: row;\n  width: 250px;\n  max-height: 50px;\n}\n.History_inputAndPreviewContainer__1eo-F {\n  display: flex;\n}\n", "",{"version":3,"sources":["webpack://src/History/History.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAS;EAAT,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,aAAa;AACf","sourcesContent":[".translationContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 40px;\n}\n\n.mainImageInputContainer {\n  display: flex;\n  flex-direction: row;\n  width: 250px;\n  max-height: 50px;\n}\n.inputAndPreviewContainer {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"translationContainer": "History_translationContainer__38cXt",
	"mainImageInputContainer": "History_mainImageInputContainer__3sjcO",
	"inputAndPreviewContainer": "History_inputAndPreviewContainer__1eo-F"
};
export default ___CSS_LOADER_EXPORT___;
