import React from "react";

import {
  jsonServerRestClient,
  fetchUtils,
  Admin,
  Resource,
  Delete,
} from "admin-on-rest";
import authClient from "./authClient";

import { UserList } from "./users";
import { BrowserRouter as Router, useHistory } from "react-router-dom";

import { BulletinList } from "./analyticalBulletin/analyticalBulletin";
import BulletinCreate from "./analyticalBulletin/analyticalBulletinCreate";
import BulletinUpdate from "./analyticalBulletin/analyticalBulletinUpdate";

import { NewsList, NewsCreate, NewsEdit } from "./news/news";

import { CouncilOfTrustees } from "./CouncilOfTrustees/CouncilOfTrustees";
import CouncilOfTrusteesCreate from "./CouncilOfTrustees/CouncilOfTrusteesCreate";
import CouncilOfTrusteesUpdate from "./CouncilOfTrustees/CouncilOfTrusteesUpdate";
import { Staff } from "./Staff/Staff";
import StaffCreate from "./Staff/StaffCreate";
import StaffUpdate from "./Staff/StaffUpdate";
import { ScientificCouncil } from "./ScientificCouncil/ScientificCouncil";
import ScientificCouncilCreate from "./ScientificCouncil/ScientificCouncilCreate";
import ScientificCouncilUpdate from "./ScientificCouncil/ScientificCouncilUpdate";
import {
  ProjectsList,
  ProjectsEdit,
  ProjectsCreate,
} from "./projects/projects";

import { Rules } from "./Rules/Rules";
import RulesCreate from "./Rules/RulesCreate";
import RulesUpdate from "./Rules/RulesUpdate";
import { Donors } from "./Donors/Donors";
import DonorsCreate from "./Donors/DonorsCreate";
import DonorsUpdate from "./Donors/DonorsUpdate";
import { Partners } from "./Partners/Partners";
import PartnersUpdate from "./Partners/PartnersUpdate";
import PartnersCreate from "./Partners/PartnersCreate";
import { TheoriesList } from "./Theories/Theories";
import TheoriesCreate from "./Theories/TheoriesCreate";
import TheoriesUpdate from "./Theories/TheoriesUpdate";
import { ExWorkers } from "./ExWorkers/ExWorkers";
import ExWorkersCreate from "./ExWorkers/ExWorkersCreate";
import ExWorkersUpdate from "./ExWorkers/ExWorkersUpdate";

import { Students } from "./Students/Students";
import StudentsCreate from "./Students/StudentsCreate";
import StudentsUpdate from "./Students/StudentsUpdate";
import { HistoryList } from "./History/History";
import HistoryUpdate from "./History/HistoryUpdate";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};
const restClient = jsonServerRestClient(
  "https://api.cccs.am/api/v1",
  httpClient
);

const App = () => (
  <Router>
    <Admin title="CCCS Admin" authClient={authClient} restClient={restClient}>
      <Resource name="users" list={UserList} />
      <Resource
        name="news"
        list={NewsList}
        edit={NewsEdit}
        create={NewsCreate}
        remove={Delete}
      />
      <Resource
        name="bulletin"
        list={BulletinList}
        create={BulletinCreate}
        edit={BulletinUpdate}
        remove={Delete}
      />
      <Resource
        name="staff"
        list={Staff}
        create={StaffCreate}
        edit={StaffUpdate}
        remove={Delete}
        options={{ label: "Staff" }}
      />
      <Resource
        name="council-of-trustees"
        list={CouncilOfTrustees}
        create={CouncilOfTrusteesCreate}
        edit={CouncilOfTrusteesUpdate}
        remove={Delete}
      />
      <Resource
        name="scientific-council"
        list={ScientificCouncil}
        create={ScientificCouncilCreate}
        edit={ScientificCouncilUpdate}
        remove={Delete}
      />
      <Resource
        name="projects"
        list={ProjectsList}
        edit={ProjectsEdit}
        create={ProjectsCreate}
        remove={Delete}
      />
      <Resource
        name="rules"
        list={Rules}
        create={RulesCreate}
        edit={RulesUpdate}
        remove={Delete}
      />
      <Resource
        name="donors"
        list={Donors}
        edit={DonorsUpdate}
        create={DonorsCreate}
        remove={Delete}
      />
      <Resource
        name="partners"
        list={Partners}
        edit={PartnersUpdate}
        create={PartnersCreate}
        remove={Delete}
      />
      <Resource
        name="theories"
        list={TheoriesList}
        create={TheoriesCreate}
        edit={TheoriesUpdate}
        remove={Delete}
      />
      <Resource
        name="exworkers"
        list={ExWorkers}
        create={ExWorkersCreate}
        edit={ExWorkersUpdate}
        remove={Delete}
      />
      <Resource
        name="students"
        list={Students}
        create={StudentsCreate}
        edit={StudentsUpdate}
        remove={Delete}
      />
      <Resource name="history" list={HistoryList} edit={HistoryUpdate} />
    </Admin>
  </Router>
);

export default App;
