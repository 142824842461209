import * as React from "react";
import { useFormik } from "formik";
import axios from "axios";
import { useHistory } from "react-router-dom";
import styles from "./CouncilOfTrustees.module.css";
// ------------------------------------------------------------------------------------
// import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MaterialTextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Edit } from "admin-on-rest";

const validate = (values) => {
  const errors = {};

  if (!values.image) {
    errors.image = "Member image is required!";
  }
  if (!values.email) {
    errors.email = "Email is required!";
  }
  if (!values.translations.en.name) {
    errors.nameEn = "Name is required!";
  }
  if (!values.translations.hy.name) {
    errors.nameHy = "Name is required!";
  }
  if (!values.translations.en.bio) {
    errors.bioEn = "Biography is required!";
  }
  if (!values.translations.hy.bio) {
    errors.bioHy = "Biography is required!";
  }
  if (!values.translations.hy.position) {
    errors.positionHy = "Position is required!";
  }
  if (!values.translations.en.position) {
    errors.positionEn = "Position is required!";
  }
  return errors;
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: "none",
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const CouncilOfTrusteesUpdate = (props) => {
  const [previewImageUrls, setPreviewImageUrls] = React.useState({});
  const [value, setValue] = React.useState("one");
  const classes = useStyles();
  const [initialData, setInitialData] = React.useState({});

  React.useEffect(async () => {
    let response = await axios({
      method: "GET",
      baseURL: `https://api.cccs.am/api/v1/council-of-trustees/${props.match.params.id}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    setInitialData(response.data);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const history = useHistory({
    forceRefresh: true,
  });
  const formData = new FormData();
  const formik = useFormik({
    initialValues: {
      translations: {
        hy: {
          name: null,
          position: null,
          bio: null,
        },
        en: {
          name: null,
          position: null,
          bio: null,
        },
      },
      image: "",
      email: null,
    },
    validateOnChange: false,
    validateOnBlur: false,
    // validate,
    onSubmit: async (values) => {
      if (values.image) {
        formData.append("image", values.image);
      } else {
        formData.append("image", initialData.image);
      }
      if (values.email) {
        formData.append("email", values.email);
      } else {
        formData.append("email", initialData.email);
      }
      if (values.translations.en.name) {
        formData.append("translations[en][name]", values.translations.en.name);
      } else {
        formData.append(
          "translations[en][name]",
          initialData.translations.en.name
        );
      }
      if (values.translations.hy.name) {
        formData.append("translations[hy][name]", values.translations.hy.name);
      } else {
        formData.append(
          "translations[hy][name]",
          initialData.translations.hy.name
        );
      }
      if (values.translations.en.position) {
        formData.append(
          "translations[en][position]",
          values.translations.en.position
        );
      } else {
        formData.append(
          "translations[en][position]",
          initialData.translations.en.position
        );
      }
      if (values.translations.hy.position) {
        formData.append(
          "translations[hy][position]",
          values.translations.hy.position
        );
      } else {
        formData.append(
          "translations[hy][position]",
          initialData.translations.hy.position
        );
      }
      if (values.translations.en.bio) {
        formData.append("translations[en][bio]", values.translations.en.bio);
      } else {
        formData.append(
          "translations[en][bio]",
          initialData.translations.en.bio
        );
      }
      if (values.translations.hy.bio) {
        formData.append("translations[hy][bio]", values.translations.hy.bio);
      } else {
        formData.append(
          "translations[hy][bio]",
          initialData.translations.hy.bio
        );
      }
      for (var pair of formData.entries()) {
        //console.log(pair[0] + " - " + pair[1]);
      }
      const response = await axios({
        method: "PATCH",
        baseURL: `https://api.cccs.am/api/v1/council-of-trustees/${props.match.params.id}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
      });
      history.push("/#/council-of-trustees");
      window.location.reload();
    },
  });

  return (
    <Edit {...props}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.formContainer}>
          <div className={styles.imageAndEmailContainer}>
            <input
              accept="image/*"
              type="file"
              name="image"
              className={classes.input}
              onChange={(e) => {
                setPreviewImageUrls({
                  ...previewImageUrls,
                  image: URL.createObjectURL(e.target.files[0]),
                });
                formik.setFieldValue("image", e.currentTarget.files[0]);
              }}
              id="upload-main-image"
            />
            <label htmlFor="upload-main-image">
              <div className={styles.inputAndPreviewContainer}>
                <div className={styles.mainImageInputContainer}>
                  <Button
                    variant="outlined"
                    component="span"
                    style={{
                      width: "300px",
                      backgroundColor: "#00BCD7",
                      color: "white",
                      fontWeight: "700",
                    }}
                  >
                    Upload member image
                  </Button>
                  <IconButton style={{ color: "#00BCD7" }} component="span">
                    <PhotoCamera />
                  </IconButton>
                </div>
                <img
                  src={
                    previewImageUrls.image
                      ? previewImageUrls.image
                      : initialData.image
                  }
                  style={{ maxWidth: "200px" }}
                />
              </div>
            </label>
            {formik.errors.image ? (
              <div className={styles.errorContainer}>{formik.errors.image}</div>
            ) : null}
            <MaterialTextField
              style={{ marginBottom: "15px", width: "100%" }}
              id="email"
              variant="outlined"
              label="email"
              name="email"
              focused
              value={
                formik.values.email !== null
                  ? formik.values.email
                  : initialData.email
              }
              onChange={formik.handleChange}
            />
            {formik.errors.email ? (
              <div className={styles.errorContainer}>{formik.errors.email}</div>
            ) : null}
          </div>
          <AppBar position="static" style={{ backgroundColor: "#00BCD7" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="wrapped label tabs example"
            >
              <Tab value="one" label="Armenian" wrapped {...a11yProps("one")} />
              <Tab value="two" label="English" {...a11yProps("two")} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index="one">
            <div className={styles.translationContainer}>
              <div>
                <MaterialTextField
                  style={{ marginBottom: "15px", width: "100%" }}
                  id="nameHy"
                  variant="outlined"
                  label="name"
                  name="translations.hy.name"
                  focused
                  value={
                    formik.values.translations.hy.name !== null
                      ? formik.values.translations.hy.name
                      : initialData.translations?.hy?.name
                  }
                  onChange={formik.handleChange}
                />
                {formik.errors.nameHy ? (
                  <div className={styles.errorContainer}>
                    {formik.errors.nameHy}
                  </div>
                ) : null}
              </div>
              <div>
                <MaterialTextField
                  style={{ marginBottom: "15px", width: "100%" }}
                  id="positionHy"
                  variant="outlined"
                  label="position"
                  focused
                  name="translations.hy.position"
                  value={
                    formik.values.translations.hy.position !== null
                      ? formik.values.translations.hy.position
                      : initialData.translations?.hy?.position
                  }
                  onChange={formik.handleChange}
                />
                {formik.errors.positionHy ? (
                  <div className={styles.errorContainer}>
                    {formik.errors.positionHy}
                  </div>
                ) : null}
              </div>
              <div>
                <MaterialTextField
                  style={{ marginBottom: "15px", width: "100%" }}
                  id="bioHy"
                  variant="outlined"
                  label="bio"
                  focused
                  name="translations.hy.bio"
                  value={
                    formik.values.translations.hy.bio !== null
                      ? formik.values.translations.hy.bio
                      : initialData.translations?.hy?.bio
                  }
                  onChange={formik.handleChange}
                />
                {formik.errors.bioHy ? (
                  <div className={styles.errorContainer}>
                    {formik.errors.bioHy}
                  </div>
                ) : null}
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index="two">
            <div className={styles.translationContainer}>
              <div>
                <MaterialTextField
                  style={{ marginBottom: "15px", width: "100%" }}
                  id="nameEn"
                  variant="outlined"
                  label="name"
                  focused
                  name="translations.en.name"
                  value={
                    formik.values.translations.en.name !== null
                      ? formik.values.translations.en.name
                      : initialData.translations?.en?.name
                  }
                  onChange={formik.handleChange}
                />
                {formik.errors.nameEn ? (
                  <div className={styles.errorContainer}>
                    {formik.errors.nameEn}
                  </div>
                ) : null}
              </div>
              <div>
                <MaterialTextField
                  style={{ marginBottom: "15px", width: "100%" }}
                  id="positionEn"
                  variant="outlined"
                  label="position"
                  focused
                  name="translations.en.position"
                  value={
                    formik.values.translations.en.position !== null
                      ? formik.values.translations.en.position
                      : initialData.translations?.en?.position
                  }
                  onChange={formik.handleChange}
                />
                {formik.errors.positionEn ? (
                  <div className={styles.errorContainer}>
                    {formik.errors.positionEn}
                  </div>
                ) : null}
              </div>
              <div>
                <MaterialTextField
                  style={{ marginBottom: "15px", width: "100%" }}
                  id="bioEn"
                  variant="outlined"
                  label="bio"
                  focused
                  name="translations.en.bio"
                  value={
                    formik.values.translations.en.bio !== null
                      ? formik.values.translations.en.bio
                      : initialData.translations?.en?.bio
                  }
                  onChange={formik.handleChange}
                />
                {formik.errors.bioEn ? (
                  <div className={styles.errorContainer}>
                    {formik.errors.bioEn}
                  </div>
                ) : null}
              </div>
            </div>
          </TabPanel>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            style={{ backgroundColor: "#00BCD4", width: "15%" }}
          >
            Update
          </Button>
        </div>
      </form>
    </Edit>
  );
};

export default CouncilOfTrusteesUpdate;
