import * as React from "react";
import { List, Datagrid, TextField, EmailField, Filter, TextInput } from "admin-on-rest";

const UsersFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" search="mail" source="title" alwaysOn />
  </Filter>
);

export const UserList = (props) => (
  <List {...props} perPage={5} filters={<UsersFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <EmailField source="mail" />
      <TextField source="message" />
    </Datagrid>
  </List>
);
