import * as React from "react";
import { useFormik } from "formik";
import axios from "axios";
import { useHistory } from "react-router-dom";
import styles from "./History.module.css";

// ------------------------------------------------------------------------------------
// import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MaterialTextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: "none",
  },
}));

const validate = (values) => {
  const errors = {};

  if (!values.mainImage) {
    errors.mainImage = "Main image is required!";
  }
  if (values.categorie.length === 0) {
    errors.categorie = "You must choose at least one type!";
  }
  if (!values.translations.en.title) {
    errors.titleEn = "Title is required!";
  }
  if (!values.translations.hy.title) {
    errors.titleHy = "Title is required!";
  }
  if (!values.translations.en.previewText) {
    errors.previewTextEn = "Preview text is required!";
  }
  if (!values.translations.hy.previewText) {
    errors.previewTextHy = "Preview text is required!";
  }
  return errors;
};

const HistoryUpdate = (props) => {
  const history = useHistory({
    forceRefresh: true,
  });
  const [previewImageUrls, setPreviewImageUrls] = React.useState({});
  const classes = useStyles();
  const [initialData, setInitialData] = React.useState({});
  const [value, setValue] = React.useState("one");
  const [sliderImages, setSliderImages] = React.useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  React.useEffect(async () => {
    let response = await axios({
      method: "GET",
      baseURL: `https://api.cccs.am/api/v1/history/${props.match.params.id}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    setInitialData(response.data);
  }, []);
  const formData = new FormData();
  const formik = useFormik({
    initialValues: {
      mainImage: "",
      sliderImages: [],
      translations: {
        hy: {
          aboutText: null,
          possibilitiesText: null,
          foundationText: null,
          civilizedText: null,
          researchesText: null,
          culturalText: null,
        },
        en: {
          aboutText: null,
          possibilitiesText: null,
          foundationText: null,
          civilizedText: null,
          researchesText: null,
          culturalText: null,
        },
      },
    },
    validateOnChange: false,
    validateOnBlur: false,
    // validate,
    onSubmit: async (values) => {
      if (values.mainImage) {
        formData.append("mainImage", values.mainImage);
      }
      if (values.sliderImages && values.sliderImages.length > 0) {
        for (let sliderImage of values.sliderImages) {
          formData.append("sliderImages", sliderImage);
        }
      } else {
        for (let sliderImage of initialData.sliderImages) {
          formData.append("sliderImages", sliderImage);
        }
      }
      if (values.translations.hy.aboutText) {
        formData.append(
          "translations[hy][aboutText]",
          values.translations.hy.aboutText
        );
      } else {
        formData.append(
          "translations[hy][aboutText]",
          initialData.translations.hy.aboutText
        );
      }
      if (values.translations.hy.possibilitiesText) {
        formData.append(
          "translations[hy][possibilitiesText]",
          values.translations.hy.possibilitiesText
        );
      } else {
        formData.append(
          "translations[hy][possibilitiesText]",
          initialData.translations.hy.possibilitiesText
        );
      }
      if (values.translations.hy.foundationText) {
        formData.append(
          "translations[hy][foundationText]",
          values.translations.hy.foundationText
        );
      } else {
        formData.append(
          "translations[hy][foundationText]",
          initialData.translations.hy.foundationText
        );
      }
      if (values.translations.hy.civilizedText) {
        formData.append(
          "translations[hy][civilizedText]",
          values.translations.hy.civilizedText
        );
      } else {
        formData.append(
          "translations[hy][civilizedText]",
          initialData.translations.hy.civilizedText
        );
      }
      if (values.translations.hy.researchesText) {
        formData.append(
          "translations[hy][researchesText]",
          values.translations.hy.researchesText
        );
      } else {
        formData.append(
          "translations[hy][researchesText]",
          initialData.translations.hy.researchesText
        );
      }
      if (values.translations.hy.culturalText) {
        formData.append(
          "translations[hy][culturalText]",
          values.translations.hy.culturalText
        );
      } else {
        formData.append(
          "translations[hy][culturalText]",
          initialData.translations.hy.culturalText
        );
      }
      if (values.translations.en.aboutText) {
        formData.append(
          "translations[en][aboutText]",
          values.translations.en.aboutText
        );
      } else {
        formData.append(
          "translations[en][aboutText]",
          initialData.translations.en.aboutText
        );
      }
      if (values.translations.en.possibilitiesText) {
        formData.append(
          "translations[en][possibilitiesText]",
          values.translations.en.possibilitiesText
        );
      } else {
        formData.append(
          "translations[en][possibilitiesText]",
          initialData.translations.en.possibilitiesText
        );
      }
      if (values.translations.en.foundationText) {
        formData.append(
          "translations[en][foundationText]",
          values.translations.en.foundationText
        );
      } else {
        formData.append(
          "translations[en][foundationText]",
          initialData.translations.en.foundationText
        );
      }
      if (values.translations.en.civilizedText) {
        formData.append(
          "translations[en][civilizedText]",
          values.translations.en.civilizedText
        );
      } else {
        formData.append(
          "translations[en][civilizedText]",
          initialData.translations.en.civilizedText
        );
      }
      if (values.translations.en.researchesText) {
        formData.append(
          "translations[en][researchesText]",
          values.translations.en.researchesText
        );
      } else {
        formData.append(
          "translations[en][researchesText]",
          initialData.translations.en.researchesText
        );
      }
      if (values.translations.en.culturalText) {
        formData.append(
          "translations[en][culturalText]",
          values.translations.en.culturalText
        );
      } else {
        formData.append(
          "translations[en][culturalText]",
          initialData.translations.en.culturalText
        );
      }
      for (let pair of formData.entries()) {
        // //console.log(`${pair[0]}, ${pair[1]}`);
      }
      const response = await axios({
        method: "PATCH",
        baseURL: `https://api.cccs.am/api/v1/history/${props.match.params.id}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
      });
      history.push("/#/history");
      window.location.reload();
    },
  });

  React.useEffect(() => {
    for (let i = 0; i < sliderImages.length; i++) {
      formik.values.sliderImages.push(sliderImages[i]);
    }
  }, [sliderImages]);
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.formWrapper}>
        <div className={styles.formContainer}>
          <input
            accept="image/*"
            type="file"
            name="mainImage"
            className={classes.input}
            onChange={(e) => {
              setPreviewImageUrls({
                ...previewImageUrls,
                image: URL.createObjectURL(e.target.files[0]),
              });
              formik.setFieldValue("mainImage", e.currentTarget.files[0]);
            }}
            id="upload-main-image"
          />
          <label htmlFor="upload-main-image">
            <div className={styles.inputAndPreviewContainer}>
              <div className={styles.mainImageInputContainer}>
                <Button
                  variant="outlined"
                  component="span"
                  style={{
                    width: "200px",
                    backgroundColor: "#00BCD7",
                    color: "white",
                    fontWeight: "700",
                  }}
                >
                  Upload main image
                </Button>
                <IconButton style={{ color: "#00BCD7" }} component="span">
                  <PhotoCamera />
                </IconButton>
              </div>
              <img
                src={
                  previewImageUrls.image
                    ? previewImageUrls.image
                    : initialData.mainImage
                }
                style={{ maxWidth: "200px" }}
              />
            </div>
          </label>
          {formik.errors.mainImage ? (
            <div className={styles.errorContainer}>
              {formik.errors.mainImage}
            </div>
          ) : null}
          <input
            accept="image/*"
            multiple
            type="file"
            name="sliderImages"
            className={classes.input}
            onChange={(e) => {
              setSliderImages(() => {
                let sliderImagesArray = [];
                for (let i = 0; i < e.currentTarget.files.length; i++) {
                  sliderImagesArray.push(e.currentTarget.files[i]);
                }
                return sliderImagesArray;
              });
            }}
            id="upload-slider-images"
          />
          <label htmlFor="upload-slider-images">
            <div className={styles.inputAndPreviewContainer}>
              <div className={styles.mainImageInputContainer}>
                <Button
                  variant="outlined"
                  component="span"
                  style={{
                    width: "200px",
                    backgroundColor: "#00BCD7",
                    color: "white",
                    fontWeight: "700",
                  }}
                >
                  Upload slider images
                </Button>
                <IconButton style={{ color: "#00BCD7" }} component="span">
                  <PhotoCamera />
                </IconButton>
              </div>
              {sliderImages.length > 0
                ? sliderImages.map((sliderImage) => (
                  <img
                    src={URL.createObjectURL(sliderImage)}
                    style={{ maxWidth: 200, marginLeft: 5 }}
                  />
                ))
                : initialData.sliderImages?.map((sliderImageURL) => (
                  <img
                    src={sliderImageURL}
                    style={{ maxWidth: 200, marginLeft: 5 }}
                  />
                ))}
            </div>
          </label>
          {formik.errors.mainImage ? (
            <div className={styles.errorContainer}>
              {formik.errors.mainImage}
            </div>
          ) : null}
          <AppBar position="static" style={{ backgroundColor: "#00BCD7" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="wrapped label tabs example"
            >
              <Tab
                value="one"
                label="Armenian"
                wrapped
                {...a11yProps("one")}
              />
              <Tab value="two" label="English" {...a11yProps("two")} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index="one">
            <div className={styles.translationContainer}>
              <MaterialTextField
                focused
                multiline
                style={{ marginBottom: "15px", width: "50%" }}
                id="aboutTextHy"
                label="about text"
                variant="outlined"
                name="translations.hy.aboutText"
                value={
                  formik.values.translations.hy.aboutText !== null
                    ? formik.values.translations.hy.aboutText
                    : initialData.translations?.hy?.aboutText
                }
                onChange={formik.handleChange}
              />
              <MaterialTextField
                focused
                multiline
                style={{ marginBottom: "15px", width: "50%" }}
                id="culturalTextHy"
                label="institutes"
                variant="outlined"
                name="translations.hy.culturalText"
                value={
                  formik.values.translations.hy.culturalText !== null
                    ? formik.values.translations.hy.culturalText
                    : initialData.translations?.hy?.culturalText
                }
                onChange={formik.handleChange}
              />
              <MaterialTextField
                focused
                multiline
                style={{ marginBottom: "15px", width: "50%" }}
                id="possibilitiesTextHy"
                label="opportunities"
                variant="outlined"
                name="translations.hy.possibilitiesText"
                value={
                  formik.values.translations.hy.possibilitiesText !== null
                    ? formik.values.translations.hy.possibilitiesText
                    : initialData.translations?.hy?.possibilitiesText
                }
                onChange={formik.handleChange}
              />
              <MaterialTextField
                focused
                multiline
                style={{ marginBottom: "15px", width: "50%" }}
                id="foundationTextHy"
                label="value"
                variant="outlined"
                name="translations.hy.foundationText"
                value={
                  formik.values.translations.hy.foundationText !== null
                    ? formik.values.translations.hy.foundationText
                    : initialData.translations?.hy?.foundationText
                }
                onChange={formik.handleChange}
              />
              <MaterialTextField
                focused
                multiline
                style={{ marginBottom: "15px", width: "50%" }}
                id="civilizedTextHy"
                label="culture"
                variant="outlined"
                name="translations.hy.civilizedText"
                value={
                  formik.values.translations.hy.civilizedText !== null
                    ? formik.values.translations.hy.civilizedText
                    : initialData.translations?.hy?.civilizedText
                }
                onChange={formik.handleChange}
              />
              <MaterialTextField
                focused
                multiline
                style={{ marginBottom: "15px", width: "50%" }}
                id="researchesTextHy"
                label="civilization"
                variant="outlined"
                name="translations.hy.researchesText"
                value={
                  formik.values.translations.hy.researchesText !== null
                    ? formik.values.translations.hy.researchesText
                    : initialData.translations?.hy?.researchesText
                }
                onChange={formik.handleChange}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index="two">
            <div className={styles.translationContainer}>
              <MaterialTextField
                focused
                multiline
                style={{ marginBottom: "15px", width: "50%" }}
                id="aboutTextEn"
                label="about text"
                variant="outlined"
                name="translations.en.aboutText"
                value={
                  formik.values.translations.en.aboutText !== null
                    ? formik.values.translations.en.aboutText
                    : initialData.translations?.en?.aboutText
                }
                onChange={formik.handleChange}
              />
              <MaterialTextField
                focused
                multiline
                style={{ marginBottom: "15px", width: "50%" }}
                id="culturalTextEn"
                label="institutes"
                variant="outlined"
                name="translations.en.culturalText"
                value={
                  formik.values.translations.en.culturalText !== null
                    ? formik.values.translations.en.culturalText
                    : initialData.translations?.en?.culturalText
                }
                onChange={formik.handleChange}
              />
              <MaterialTextField
                focused
                multiline
                style={{ marginBottom: "15px", width: "50%" }}
                id="possibilitiesTextEn"
                label="opportunities"
                variant="outlined"
                name="translations.en.possibilitiesText"
                value={
                  formik.values.translations.en.possibilitiesText !== null
                    ? formik.values.translations.en.possibilitiesText
                    : initialData.translations?.en?.possibilitiesText
                }
                onChange={formik.handleChange}
              />
              <MaterialTextField
                focused
                multiline
                style={{ marginBottom: "15px", width: "50%" }}
                id="foundationTextEn"
                label="value"
                variant="outlined"
                name="translations.en.foundationText"
                value={
                  formik.values.translations.en.foundationText !== null
                    ? formik.values.translations.en.foundationText
                    : initialData.translations?.en?.foundationText
                }
                onChange={formik.handleChange}
              />
              <MaterialTextField
                focused
                multiline
                style={{ marginBottom: "15px", width: "50%" }}
                id="civilizedTextEn"
                label="culture"
                variant="outlined"
                name="translations.en.civilizedText"
                value={
                  formik.values.translations.en.civilizedText !== null
                    ? formik.values.translations.en.civilizedText
                    : initialData.translations?.en?.civilizedText
                }
                onChange={formik.handleChange}
              />
              <MaterialTextField
                focused
                multiline
                style={{ marginBottom: "15px", width: "50%" }}
                id="researchesTextEn"
                label="civilization"
                variant="outlined"
                name="translations.en.researchesText"
                value={
                  formik.values.translations.en.researchesText !== null
                    ? formik.values.translations.en.researchesText
                    : initialData.translations?.en?.researchesText
                }
                onChange={formik.handleChange}
              />
            </div>
          </TabPanel>

          <div className={styles.buttonContainer}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              style={{ backgroundColor: "#00BCD4", width: "15%" }}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </form>

  );
};
export default HistoryUpdate;
