import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  Filter,
  TextInput,
  FunctionField,
  EditButton,
  Edit,
  SimpleForm,
  Create,
} from "admin-on-rest";
import ProjectsUpdateForm from "./projectsUpdateForm";
import ProjectsCreateForm from "./projectsCreateForm";

const ProjectsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="title" alwaysOn />
  </Filter>
);

const EditedProjectTitle = ({ record }) => {
  return <span>Project {record ? `${record.id}` : "undefined id"}</span>
}

export const ProjectsEdit = (props) => {
  return (
    <Edit title={<EditedProjectTitle />} {...props}>
      <ProjectsUpdateForm props={props}></ProjectsUpdateForm>
    </Edit>
  )
}

export const ProjectsCreate = (props) => {
  return (
    <Create {...props}>
      <ProjectsCreateForm></ProjectsCreateForm>
    </Create>
  );
};

export const ProjectsList = (props) => (
  <List {...props} perPage={5} filters={<ProjectsFilter />}>
    <Datagrid rowClick="edit">
      <TextField label="id" source="id" />
      <TextField label="title" source="translations.hy.title" />
      <TextField label="preview text" source="translations.hy.previewText" />
      <FunctionField
        label="main image"
        render={(record) =>
          <img src={record.mainImage}
            style={{ width: 100 }}
          />
        }
      />
      <TextField label="categorie" source="categorie" />
      {/* <FunctionField
        label="sliderImages"
        render={(record) =>
          record.sliderImages.map((image) =>
            <img
              src={image}
              style={{ width: 100, margin: 10 }}
            />
          )
        } /> */}
      <EditButton />
    </Datagrid>
  </List>
);
