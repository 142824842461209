// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Partners_mainImageInputContainer__QYui2 {\n  display: flex;\n  flex-direction: row;\n  width: 250px;\n  max-height: 50px;\n}\n.Partners_inputAndPreviewContainer__1A5Oj {\n  display: flex;\n  margin-bottom: 20px;\n  width: 250px;\n}\n.Partners_labelForImage__2HsZ6 {\n  width: 250px;\n  display: flex;\n}\n.Partners_errorContainer__1UoQI {\n  width: 100%;\n  height: 60px;\n  border-radius: 4px;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  padding-left: 20px;\n  color: white;\n  background-color: rgba(255, 0, 0, 0.397);\n  border: 1px solid red;\n  font-size: 20px;\n}\n", "",{"version":3,"sources":["webpack://src/Partners/Partners.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,YAAY;EACZ,aAAa;AACf;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,wCAAwC;EACxC,qBAAqB;EACrB,eAAe;AACjB","sourcesContent":[".mainImageInputContainer {\n  display: flex;\n  flex-direction: row;\n  width: 250px;\n  max-height: 50px;\n}\n.inputAndPreviewContainer {\n  display: flex;\n  margin-bottom: 20px;\n  width: 250px;\n}\n.labelForImage {\n  width: 250px;\n  display: flex;\n}\n.errorContainer {\n  width: 100%;\n  height: 60px;\n  border-radius: 4px;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  padding-left: 20px;\n  color: white;\n  background-color: rgba(255, 0, 0, 0.397);\n  border: 1px solid red;\n  font-size: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainImageInputContainer": "Partners_mainImageInputContainer__QYui2",
	"inputAndPreviewContainer": "Partners_inputAndPreviewContainer__1A5Oj",
	"labelForImage": "Partners_labelForImage__2HsZ6",
	"errorContainer": "Partners_errorContainer__1UoQI"
};
export default ___CSS_LOADER_EXPORT___;
