import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
} from "admin-on-rest";
export const HistoryList = (props) => (
  <List {...props} perPage={5}>
    <Datagrid rowClick="edit">
      <TextField label="id" source="id"></TextField>
      <TextField label="about" source="title"></TextField>
      <EditButton />
    </Datagrid>
  </List>
);
