import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  FunctionField,
} from "admin-on-rest";

export const TheoriesList = (props) => (
  <List {...props} perPage={5}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="translations.hy.title" label="Title" />
      <FunctionField
        label="main image"
        render={(record) => (
          <img src={record.mainImage} style={{ width: 100 }} />
        )}
      />
      <EditButton />
    </Datagrid>
  </List>
);
