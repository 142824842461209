import * as React from "react";
import { useFormik } from "formik";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Edit } from "admin-on-rest";
import Button from "@material-ui/core/Button";
import MaterialTextField from "@material-ui/core/TextField";
import styles from "./Students.module.css";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const StudentsUpdate = (props) => {
  const [value, setValue] = React.useState("one");
  const [initialData, setInitialData] = React.useState({});
  const history = useHistory({
    forceRefresh: true,
  });
  React.useEffect(async () => {
    let response = await axios({
      method: "GET",
      baseURL: `https://api.cccs.am/api/v1/students/${props.match.params.id}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    setInitialData(response.data);
  }, []);
  const formData = new FormData();
  const formik = useFormik({
    initialValues: {
      translations: {
        hy: {
          info: null,
        },
        en: {
          info: null,
        },
      },
    },
    onSubmit: async (values) => {
      if (values.translations.hy.info) {
        formData.append("translations[hy][info]", values.translations.hy.info);
      } else {
        formData.append(
          "translations[hy][info]",
          initialData.translations.hy.info
        );
      }
      if (values.translations.en.info) {
        formData.append("translations[en][info]", values.translations.en.info);
      } else {
        formData.append(
          "translations[en][info]",
          initialData.translations.en.info
        );
      }
        for (let pair of formData.entries()) {
          //console.log(pair[0] + ", " + pair[1]);
        }
      await axios({
        method: "PATCH",
        baseURL: `https://api.cccs.am/api/v1/students/${props.match.params.id}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
      });
      history.push("/#/students");
      window.location.reload();
    },
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Edit {...props}>
      <form onSubmit={formik.handleSubmit}>
        <AppBar position="static" style={{ backgroundColor: "#00BCD7" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
          >
            <Tab value="one" label="Armenian" wrapped {...a11yProps("one")} />
            <Tab value="two" label="English" {...a11yProps("two")} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index="one">
          <div className={styles.inputContainer}>
            <MaterialTextField
              style={{ marginBottom: "15px", width: "50%" }}
              id="info"
              focused
              variant="outlined"
              label="info"
              name="translations.hy.info"
              value={
                formik.values.translations.hy.info !== null
                  ? formik.values.translations.hy.info
                  : initialData.translations?.hy?.info
              }
              onChange={formik.handleChange}
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index="two">
          <div className={styles.inputContainer}>
            <MaterialTextField
              style={{ marginBottom: "15px", width: "50%" }}
              id="info"
              focused
              variant="outlined"
              label="info"
              name="translations.en.info"
              value={
                formik.values.translations.en.info !== null
                  ? formik.values.translations.en.info
                  : initialData.translations?.en?.info
              }
              onChange={formik.handleChange}
            />
          </div>
        </TabPanel>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          style={{ backgroundColor: "#00BCD4", width: "15%" }}
        >
          Update
        </Button>
      </form>
    </Edit>
  );
};

export default StudentsUpdate;
