import * as React from "react";
import { List, Datagrid, TextField, EditButton } from "admin-on-rest";

export const Students = (props) => {
  return (
    <List {...props} perPage={5}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="translations.hy.info" label="info" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
