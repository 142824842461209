import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

export default function Editor({ updateOnChange, markedData }) {
  return (
    <CKEditor
      onReady={(editor) => {
        // You can store the "editor" and use when it is needed.
        //console.log("Editor is ready to use!", editor);
        // Insert the toolbar before the editable area.
        editor.ui
          .getEditableElement()
          .parentElement.insertBefore(
            editor.ui.view.toolbar.element,
            editor.ui.getEditableElement()
          );
        // this.editor = editor;
        updateOnChange(editor);
      }}
      onError={({ willEditorRestart }) => {
        // If the editor is restarted, the toolbar element will be created once again.
        // The `onReady` callback will be called again and the new toolbar will be added.
        // This is why you need to remove the older toolbar.
        if (willEditorRestart) {
          this.editor.ui.view.toolbar.element.remove();
        }
      }}
      onChange={(event, editor) => {
        updateOnChange(editor);
      }}
      onBlur={(event, editor) => {
        //console.log("Blur.", editor);
      }}
      onFocus={(event, editor) => {
        //console.log("Focus.", editor);
      }}
      editor={DecoupledEditor}
      data={markedData}
      config={{
        ckfinder: {
          uploadUrl: "https://api.cccs.am/api/v1/uploads",
        },
        mediaEmbed: {
          previewsInData: true,
        },
      }}
    />
  );
}
