import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  UrlField,
  ImageField,
  FunctionField,
  Edit,
  Create,
  EditButton,
  DisabledInput,
  LongTextInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  ImageInput
} from "admin-on-rest";

// const FullNameField = ({ record = {} }) => (
//   <span>
//     {record.firstName} {record.imageURL}
//   </span>
// );
// FullNameField.defaultProps = { label: "Name" };

export const BulletinList = (props) => (
  <List {...props} perPage={5}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <UrlField source="linkTo" />
      {/* <ImageField source={`imageURL` } src = "" title={`imageURL`} /> */}
      <FunctionField
          label="bulletin image"
          render={(record) => <img src={record.imageURL} style={{ width: 100 }} />}
        />

      <EditButton />
    </Datagrid>
  </List>
);

