import * as React from "react";
import { useFormik } from "formik";
import axios from "axios";
import { useHistory } from "react-router-dom";
// import styles from "./ScientificCouncil.module.css";
// ------------------------------------------------------------------------------------
// import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MaterialTextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Create } from "admin-on-rest";
import Editor from "../news/Editor";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: "none",
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const RulesCreate = (props) => {
  const [value, setValue] = React.useState("one");
  const [markedTextHy, setMarkedTextHy] = React.useState("");
  const [markedTextEn, setMarkedTextEn] = React.useState("");
  const history = useHistory({
    forceRefresh: true,
  });
  const updateMarkedTextHy = (editorData) => {
    const data = editorData.getData();
    setMarkedTextHy(data);
  };
  const updateMarkedTextEn = (editorData) => {
    const data = editorData.getData();
    setMarkedTextEn(data);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const formData = new FormData();
  const formik = useFormik({
    initialValues: {
      translations: {
        hy: {
          title: "",
          markedText: "",
        },
        en: {
          title: "",
          markedText: "",
        },
      },
    },
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      formData.append("translations[hy][title]", values.translations.hy.title);
      formData.append("translations[hy][markedText]", markedTextHy);
      formData.append("translations[en][title]", values.translations.en.title);
      formData.append("translations[en][markedText]", markedTextEn);
      await axios({
        method: "POST",
        baseURL: "https://api.cccs.am/api/v1/rules",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
      });
      history.push("/#/rules");
      window.location.reload();
    },
  });
  return (
    <Create {...props}>
      <form onSubmit={formik.handleSubmit}>
        <AppBar position="static" style={{ backgroundColor: "#00BCD7" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
          >
            <Tab value="one" label="Armenian" wrapped {...a11yProps("one")} />
            <Tab value="two" label="English" {...a11yProps("two")} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index="one">
          <MaterialTextField
            focused
            style={{ marginBottom: "15px", width: "50%" }}
            id="titleHy"
            label="title"
            variant="outlined"
            name="translations.hy.title"
            value={formik.values.translations.hy.title}
            onChange={formik.handleChange}
          />
          <Editor
            updateOnChange={updateMarkedTextHy}
            markedData={markedTextHy}
          ></Editor>
        </TabPanel>
        <TabPanel value={value} index="two">
          <MaterialTextField
            focused
            style={{ marginBottom: "15px", width: "50%" }}
            id="titleEn"
            label="title"
            variant="outlined"
            name="translations.en.title"
            value={formik.values.translations.en.title}
            onChange={formik.handleChange}
          />
          <Editor
            updateOnChange={updateMarkedTextEn}
            markedData={markedTextEn}
          ></Editor>
        </TabPanel>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          style={{ backgroundColor: "#00BCD4", width: "15%" }}
        >
          Create
        </Button>
      </form>
    </Create>
  );
};

export default RulesCreate;
