import * as React from "react";
import { useFormik } from "formik";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Create } from "admin-on-rest";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import MaterialTextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./Partners.module.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
  input: {
    display: "none",
  },
}));

const PartnersCreate = (props) => {
  const history = useHistory({
    forceRefresh: true,
  });
  const classes = useStyles();
  const [previewImageUrls, setPreviewImageUrls] = React.useState({});
  const formData = new FormData();
  const formik = useFormik({
    initialValues: {
      image: "",
    },
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      formData.append("image", values.image);
      formData.append("link", values.link);
      await axios({
        method: "POST",
        baseURL: "https://api.cccs.am/api/v1/partners",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
      });
      history.push("/#/partners");
      window.location.reload();
    },
  });
  return (
    <Create {...props}>
      <form onSubmit={formik.handleSubmit}>
        <input
          accept="image/*"
          type="file"
          name="image"
          className={classes.input}
          onChange={(e) => {
            setPreviewImageUrls({
              ...previewImageUrls,
              image: URL.createObjectURL(e.target.files[0]),
            });
            formik.setFieldValue("image", e.currentTarget.files[0]);
          }}
          id="upload-main-image"
        />
        <label
          htmlFor="upload-main-image"
          className={styles.labelForImage}
        >
          <div className={styles.inputAndPreviewContainer}>
            <div className={styles.mainImageInputContainer}>
              <Button
                variant="outlined"
                component="span"
                style={{
                  width: "200px",
                  backgroundColor: "#00BCD7",
                  color: "white",
                  fontWeight: "700",
                }}
              >
                Upload image
              </Button>
              <IconButton style={{ color: "#00BCD7" }} component="span">
                <PhotoCamera />
              </IconButton>
            </div>
            <img src={previewImageUrls.image} style={{ maxWidth: "200px" }} />
          </div>
        </label>
        {formik.errors.mainImage ? (
          <div className={styles.errorContainer}>{formik.errors.image}</div>
        ) : null}
        <div className={styles.inputContainer}>
          <MaterialTextField
            style={{ marginBottom: "15px", width: "30%" }}
            id="link"
            focused
            variant="outlined"
            label="Link"
            name="link"
            value={formik.values.link}
            onChange={formik.handleChange}
          />
        </div>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          style={{ backgroundColor: "#00BCD4", width: "15%" }}
        >
          Create
        </Button>
      </form>
    </Create>
  );
};

export default PartnersCreate;
