import * as React from "react";
import { useFormik } from "formik";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import styles from "./projects.module.css";

// ------------------------------------------------------------------------------------
// import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MaterialTextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Editor from "./editor";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: "none",
  },
}));
const ProjectsUpdateForm = ({ props }) => {
  const history = useHistory({
    forceRefresh: true,
  });
  const [previewImageUrls, setPreviewImageUrls] = React.useState({});
  const classes = useStyles();
  const [initialData, setInitialData] = React.useState({});
  const [markedTextHy, setMarkedTextHy] = React.useState("");
  const [markedTextEn, setMarkedTextEn] = React.useState("");
  const [value, setValue] = React.useState("one");
  const [checkboxValue, setCheckboxValue] = React.useState("");
  const [sliderImages, setSliderImages] = React.useState([]);

  let token = localStorage.getItem('token');

  React.useEffect(async () => {
    let response = await axios({
      method: "GET",
      baseURL: `https://api.cccs.am/api/v1/projects/${props.match.params.id}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    //console.log("initialData: ", response.data);
    setInitialData(response.data);
    setMarkedTextHy(response.data.translations.hy.markedText);
    setMarkedTextEn(response.data.translations.en.markedText);
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const formData = new FormData();
  const formik = useFormik({
    initialValues: {
      mainImage: "",
      categorie: "",
      sliderImages: [],
      translations: {
        hy: {
          title: "",
          previewText: "",
          markedText: "",
        },
        en: {
          title: "",
          previewText: "",
          markedText: "",
        },
      },
    },
    validateOnChange: false,
    validateOnBlur: false,
    // validate,
    onSubmit: async (values) => {
      if (values.sliderImages && values.sliderImages.length > 0) {
        for (let sliderImage of values.sliderImages) {
          formData.append("sliderImages", sliderImage);
        }
      } else {
        for (let sliderImage of initialData.sliderImages) {
          formData.append("sliderImages", sliderImage);
        }
      }
      if (values.categorie) {
        formData.append("categorie", values.categorie);
      } else {
        formData.append("categorie", initialData.categorie);
      }
      if (values.mainImage) {
        formData.append("mainImage", values.mainImage);
      } else {
        formData.append("mainImage", initialData.mainImage);
      }
      if (values.translations.en.title) {
        formData.append(
          "translations[en][title]",
          values.translations.en.title
        );
      } else {
        formData.append(
          "translations[en][title]",
          initialData.translations.en.title
        );
      }
      if (values.translations.en.previewText) {
        formData.append(
          "translations[en][previewText]",
          values.translations.en.previewText
        );
      } else {
        formData.append(
          "translations[en][previewText]",
          initialData.translations.en.previewText
        );
      }
      if (values.translations.hy.title) {
        formData.append(
          "translations[hy][title]",
          values.translations.hy.title
        );
      } else {
        formData.append(
          "translations[hy][title]",
          initialData.translations.hy.title
        );
      }
      if (values.translations.hy.previewText) {
        formData.append(
          "translations[hy][previewText]",
          values.translations.hy.previewText
        );
      } else {
        formData.append(
          "translations[hy][previewText]",
          initialData.translations.hy.previewText
        );
      }
      formData.append("translations[en][markedText]", markedTextEn);
      formData.append("translations[hy][markedText]", markedTextHy);

      const response = await axios({
        method: "PATCH",
        baseURL: `https://api.cccs.am/api/v1/projects/${props.match.params.id}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": `bearer ${token}`
        },
        data: formData,
      }).catch(error => {
        // alert(error);
        history.push("/#/login");
        window.location.reload();
      });
      
      history.push("/#/projects");
      window.location.reload();
    },
  });

  React.useEffect(() => {
    formik.values.categorie = checkboxValue;
  }, [checkboxValue]);

  const handleChekboxChange = (value) => {
    switch (value) {
      case "AdaptationMechanism":
        setCheckboxValue("AdaptationMechanism");
        break;
      case "ThreeSeasSystem":
        setCheckboxValue("ThreeSeasSystem");
        break;
      case "Road":
        setCheckboxValue("Road");
        break;
      case "DigitalArmenia":
        setCheckboxValue("DigitalArmenia");
        break;
      case "HmgmAndCreativeGames":
        setCheckboxValue("HmgmAndCreativeGames");
        break;
      default:
        break;
    }
  };

  const updateMarkedTextHy = (editorData) => {
    const data = editorData.getData();
    setMarkedTextHy(data);
  };
  const updateMarkedTextEn = (editorData) => {
    const data = editorData.getData();
    setMarkedTextEn(data);
  };

  React.useEffect(() => {
    for (let i = 0; i < sliderImages.length; i++) {
      formik.values.sliderImages.push(sliderImages[i]);
    }
    // //console.log(formik);
  }, [sliderImages]);

  let itemsToRender;

  if (sliderImages.length > 0) {
    itemsToRender = sliderImages.map((sliderImage) => (
      <img
        src={URL.createObjectURL(sliderImage)}
        style={{ maxWidth: 200, marginLeft: 5 }}
      />
    ));
  } else if (initialData.sliderImages) {
    itemsToRender = initialData.sliderImages.map((sliderImage) => (
      <img src={sliderImage} style={{ maxWidth: 200, marginLeft: 5 }} />
    ));
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.formWrapper}>
        <div className={styles.formContainer}>
          <input
            accept="image/*"
            type="file"
            name="mainImage"
            className={classes.input}
            onChange={(e) => {
              setPreviewImageUrls({
                ...previewImageUrls,
                image: URL.createObjectURL(e.target.files[0]),
              });
              formik.setFieldValue("mainImage", e.currentTarget.files[0]);
            }}
            id="upload-main-image"
          />
          <label htmlFor="upload-main-image">
            <div className={styles.inputAndPreviewContainer}>
              <div className={styles.mainImageInputContainer}>
                <Button
                  variant="outlined"
                  component="span"
                  style={{
                    width: "200px",
                    backgroundColor: "#00BCD7",
                    color: "white",
                    fontWeight: "700",
                  }}
                >
                  Upload main image
                </Button>
                <IconButton style={{ color: "#00BCD7" }} component="span">
                  <PhotoCamera />
                </IconButton>
              </div>
              <img
                src={
                  previewImageUrls.image
                    ? previewImageUrls.image
                    : initialData.mainImage
                }
                style={{ maxWidth: "200px" }}
              />
            </div>
          </label>
          {formik.errors.mainImage ? (
            <div className={styles.errorContainer}>
              {formik.errors.mainImage}
            </div>
          ) : null}
          <input
            accept="image/*"
            multiple
            type="file"
            name="sliderImages"
            className={classes.input}
            onChange={(e) => {
              setSliderImages(() => {
                let sliderImagesArray = [];
                for (let i = 0; i < e.currentTarget.files.length; i++) {
                  sliderImagesArray.push(e.currentTarget.files[i]);
                }
                return sliderImagesArray;
              });
            }}
            id="upload-slider-images"
          />
          <label htmlFor="upload-slider-images">
            <div className={styles.inputAndPreviewContainer}>
              <div className={styles.mainImageInputContainer}>
                <Button
                  variant="outlined"
                  component="span"
                  style={{
                    width: "200px",
                    backgroundColor: "#00BCD7",
                    color: "white",
                    fontWeight: "700",
                  }}
                >
                  Upload slider images
                </Button>
                <IconButton style={{ color: "#00BCD7" }} component="span">
                  <PhotoCamera />
                </IconButton>
              </div>
              {itemsToRender}
            </div>
          </label>
          {formik.errors.mainImage ? (
            <div className={styles.errorContainer}>
              {formik.errors.mainImage}
            </div>
          ) : null}
          <div className={styles.categoriesContainer}>
            <p style={{ fontWeight: 500, fontSize: "22px" }}>Categories</p>
            <RadioGroup row style={{ marginBottom: "30px" }}>
              <div className={styles.categoriesBox}>
                <FormControlLabel
                  control={
                    <Radio
                      value="AdaptationMechanism"
                      onChange={() =>
                        handleChekboxChange("AdaptationMechanism")
                      }
                      style={{ color: "#00BDC7" }}
                    />
                  }
                  label="AdaptationMechanism"
                />
                <FormControlLabel
                  control={
                    <Radio
                      value="ThreeSeasSystem"
                      onChange={() => handleChekboxChange("ThreeSeasSystem")}
                      style={{ color: "#00BDC7" }}
                    />
                  }
                  label="ThreeSeasSystem"
                />
                <FormControlLabel
                  control={
                    <Radio
                      value="Road"
                      onChange={() => handleChekboxChange("Road")}
                      style={{ color: "#00BDC7" }}
                    />
                  }
                  label="Road"
                />
                <FormControlLabel
                  control={
                    <Radio
                      value="DigitalArmenia"
                      onChange={() => handleChekboxChange("DigitalArmenia")}
                      style={{ color: "#00BDC7" }}
                    />
                  }
                  label="DigitalArmenia"
                />
              </div>
              <div className={styles.categoriesBox}>
                <FormControlLabel
                  control={
                    <Radio
                      value="HmgmAndCreativeGames"
                      onChange={() =>
                        handleChekboxChange("HmgmAndCreativeGames")
                      }
                      style={{ color: "#00BDC7" }}
                    />
                  }
                  label="HmgmAndCreativeGames"
                />
              </div>
            </RadioGroup>
            {formik.errors.categories ? (
              <div className={styles.errorContainer}>
                {formik.errors.categories}
              </div>
            ) : null}
          </div>
          <AppBar position="static" style={{ backgroundColor: "#00BCD7" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="wrapped label tabs example"
            >
              <Tab value="one" label="Armenian" wrapped {...a11yProps("one")} />
              <Tab value="two" label="English" {...a11yProps("two")} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index="one">
            <div className={styles.translationContainer}>
              <div>
                <MaterialTextField
                  focused
                  style={{ marginBottom: "15px", width: "100%" }}
                  id="titleHy"
                  variant="outlined"
                  label="Title"
                  name="translations.hy.title"
                  value={
                    formik.values.translations.hy.title
                      ? formik.values.translations.hy.title
                      : initialData.translations?.hy?.title
                  }
                  onChange={formik.handleChange}
                />
                {formik.errors.titleHy ? (
                  <div className={styles.errorContainer}>
                    {formik.errors.titleHy}
                  </div>
                ) : null}
              </div>
              <div>
                <MaterialTextField
                  focused
                  style={{ marginBottom: "15px", width: "100%" }}
                  id="previewTextHy"
                  variant="outlined"
                  label="Preview text"
                  name="translations.hy.previewText"
                  value={
                    formik.values.translations.hy.previewText
                      ? formik.values.translations.hy.previewText
                      : initialData.translations?.hy?.previewText
                  }
                  onChange={formik.handleChange}
                />
                {formik.errors.previewTextHy ? (
                  <div className={styles.errorContainer}>
                    {formik.errors.previewTextHy}
                  </div>
                ) : null}
              </div>
              <Editor
                updateOnChange={updateMarkedTextHy}
                markedData={markedTextHy}
              ></Editor>
            </div>
          </TabPanel>
          <TabPanel value={value} index="two">
            <div className={styles.translationContainer}>
              <div>
                <MaterialTextField
                  focused
                  style={{ marginBottom: "15px", width: "100%" }}
                  id="titleEn"
                  variant="outlined"
                  label="Title"
                  name="translations.en.title"
                  value={
                    formik.values.translations.en.title
                      ? formik.values.translations.en.title
                      : initialData.translations?.en?.title
                  }
                  onChange={formik.handleChange}
                />
                {formik.errors.titleEn ? (
                  <div className={styles.errorContainer}>
                    {formik.errors.titleEn}
                  </div>
                ) : null}
              </div>
              <div>
                <MaterialTextField
                  focused
                  style={{ marginBottom: "15px", width: "100%" }}
                  id="previewTextEn"
                  variant="outlined"
                  label="Preview text"
                  name="translations.en.previewText"
                  value={
                    formik.values.translations.en.previewText
                      ? formik.values.translations.en.previewText
                      : initialData.translations?.en?.previewText
                  }
                  onChange={formik.handleChange}
                />
                {formik.errors.previewTextEn ? (
                  <div className={styles.errorContainer}>
                    {formik.errors.previewTextEn}
                  </div>
                ) : null}
              </div>
              <Editor
                updateOnChange={updateMarkedTextEn}
                markedData={markedTextEn}
              ></Editor>
            </div>
          </TabPanel>

          <div className={styles.buttonContainer}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              style={{ backgroundColor: "#00BCD4", width: "15%" }}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};
export default ProjectsUpdateForm;
