import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from "admin-on-rest";
// import axios from "axios";

// let tokenValidTime = localStorage.getItem('tokenValidTime');
// let tokenInterval;

// const generateToken = () => {
//   // //console.log('interval has worked');
//   let refreshToken = localStorage.getItem('refreshToken');
//   axios.post(`https://api.cccs.am/api/v1/adminUsers/generateToken`, null, { params: { grant_type: refreshToken } })
//     .then(res => {
//       localStorage.removeItem("token");
//       localStorage.removeItem("refreshToken");
//       localStorage.removeItem('tokenValidTime');
//       localStorage.removeItem('refreshTokenValidTime');
//       localStorage.setItem('token', res.data.token);
//       localStorage.setItem('refreshToken', res.data.refreshToken);
//       localStorage.setItem('tokenValidTime', res.data.expiresIn);
//       localStorage.setItem('refreshTokenValidTime', res.data.refreshTokenExpiresIn);
//     })
//     .catch(err => {
//       localStorage.removeItem("token");
//       localStorage.removeItem("refreshToken");
//       localStorage.removeItem('tokenValidTime');
//       localStorage.removeItem('refreshTokenValidTime');
//       window.location.href = "/#/login";
//       window.location.reload();
//     })
// }

export default (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request("https://api.cccs.am/api/v1/adminUsers/signIn", {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ token, refreshToken, expiresIn, refreshTokenExpiresIn }) => {
        localStorage.setItem("token", token);
        // localStorage.setItem("refreshToken", refreshToken);
        // localStorage.setItem('tokenValidTime', expiresIn);
        // localStorage.setItem('refreshTokenValidTime', refreshTokenExpiresIn);
      });
  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('token');
    // localStorage.removeItem('refreshToken');
    // localStorage.removeItem('tokenValidTime');
    // localStorage.removeItem('refreshTokenValidTime');
    // clearInterval(tokenInterval);
    // //console.log('interval was cleared');
    return Promise.resolve();
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    // clearInterval(tokenInterval);
    // let intervalTime = tokenValidTime * 1000 - 60000;
    // tokenInterval = setInterval(generateToken, intervalTime);
    return localStorage.getItem('token')
      ? Promise.resolve()
      : Promise.reject();
  }
  return Promise.reject("Unknown method");
  // return Promise.resolve();
};
