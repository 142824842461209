import * as React from "react";
import { useFormik } from "formik";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Edit } from "admin-on-rest";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import MaterialTextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./Donors.module.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
  input: {
    display: "none",
  },
}));

const DonorsUpdate = (props) => {
  const history = useHistory({
    forceRefresh: true,
  });
  const [initialData, setInitialData] = React.useState({});
  const classes = useStyles();
  const [previewImageUrls, setPreviewImageUrls] = React.useState({});
  const formData = new FormData();
  React.useEffect(async () => {
    let response = await axios({
      method: "GET",
      baseURL: `https://api.cccs.am/api/v1/donors/${props.match.params.id}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    setInitialData(response.data);
  }, []);
  const formik = useFormik({
    initialValues: {
      image: "",
      link: null,
    },
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (values.image) {
        formData.append("image", values.image);
      } else {
        formData.append("image", initialData.image);
      }
      if (values.link) {
        formData.append("link", values.link);
      } else {
        formData.append("link", initialData.link);
      }
      for (let pairs of formData) {
        //console.log(pairs[0] + ", " + pairs[1]);
      }
      await axios({
        method: "PATCH",
        baseURL: `https://api.cccs.am/api/v1/donors/${props.match.params.id}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
      });
      history.push("/#/donors");
      window.location.reload();
    },
  });
  return (
    <Edit {...props}>
      <form onSubmit={formik.handleSubmit}>
        <div classname={styles.inputContainer}>
          <input
            accept="image/*"
            type="file"
            name="image"
            className={classes.input}
            onChange={(e) => {
              setPreviewImageUrls({
                ...previewImageUrls,
                image: URL.createObjectURL(e.target.files[0]),
              });
              formik.setFieldValue("image", e.currentTarget.files[0]);
            }}
            id="upload-main-image"
          />
          <label htmlFor="upload-main-image" className={styles.labelForImage}>
            <div className={styles.inputAndPreviewContainer}>
              <div className={styles.mainImageInputContainer}>
                <Button
                  variant="outlined"
                  component="span"
                  style={{
                    width: "200px",
                    backgroundColor: "#00BCD7",
                    color: "white",
                    fontWeight: "700",
                  }}
                >
                  Upload image
                </Button>
                <IconButton style={{ color: "#00BCD7" }} component="span">
                  <PhotoCamera />
                </IconButton>
              </div>
              <img
                src={
                  previewImageUrls.image
                    ? previewImageUrls.image
                    : initialData.image
                }
                style={{ maxWidth: "200px" }}
              />
            </div>
          </label>
        </div>
        {formik.errors.image ? (
          <div className={styles.errorContainer}>{formik.errors.image}</div>
        ) : null}
        <div className={styles.textFieldContainer}>
          <MaterialTextField
            style={{ marginBottom: "15px", width: "30%" }}
            id="link"
            focused
            variant="outlined"
            label="Link"
            name="link"
            value={
              formik.values.link !== null
                ? formik.values.link
                : initialData.link
            }
            onChange={formik.handleChange}
          />
        </div>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          style={{ backgroundColor: "#00BCD4", width: "15%" }}
        >
          Update
        </Button>
      </form>
    </Edit>
  );
};

export default DonorsUpdate;
