import * as React from "react";
import { useFormik } from "formik";
import axios from "axios";
import { useHistory } from "react-router-dom";
import styles from "./news.module.css";

// ------------------------------------------------------------------------------------
// import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MaterialTextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Editor from "./Editor";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
  input: {
    display: "none",
  },
}));

const validate = (values) => {
  const errors = {};

  if (!values.mainImage) {
    errors.mainImage = "Main image is required!";
  }
  if (values.categories.length === 0) {
    errors.categories = "You must choose at least one type!";
  }
  if (!values.translations.en.title) {
    errors.titleEn = "Title is required!";
  }
  if (!values.translations.hy.title) {
    errors.titleHy = "Title is required!";
  }
  if (!values.translations.en.previewText) {
    errors.previewTextEn = "Preview text is required!";
  }
  if (!values.translations.hy.previewText) {
    errors.previewTextHy = "Preview text is required!";
  }
  return errors;
};

const NewsCreateForm = () => {
  const history = useHistory({
    forceRefresh: true,
  });
  const [previewImageUrls, setPreviewImageUrls] = React.useState({});
  const classes = useStyles();
  const [markedTextHy, setMarkedTextHy] = React.useState("");
  const [markedTextEn, setMarkedTextEn] = React.useState("");
  const [value, setValue] = React.useState("one");
  const [checkboxValues, setCheckboxValues] = React.useState({});
  const [categories, seyCategories] = React.useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const formData = new FormData();
  const formik = useFormik({
    initialValues: {
      mainImage: "",
      pdfFile: "",
      categories: [],
      translations: {
        hy: {
          title: "",
          previewText: "",
          markedText: "",
        },
        en: {
          title: "",
          previewText: "",
          markedText: "",
        },
      },
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate,
    onSubmit: async (values) => {
      let categories = [];
      for (let key in checkboxValues) {
        if (checkboxValues[key]) {
          categories.push(`${key}`);
        }
      }
      for (let i = 0; i < categories.length; i++) {
        formData.append("categories", categories[i]);
      }
      // formData.append("markedText", markedText);
      formData.append("mainImage", values.mainImage);
      formData.append("pdfFile", values.pdfFile);
      formData.append("translations[en][title]", values.translations.en.title);
      formData.append(
        "translations[en][previewText]",
        values.translations.en.previewText
      );
      formData.append("translations[en][markedText]", markedTextEn);
      formData.append("translations[hy][title]", values.translations.hy.title);
      formData.append(
        "translations[hy][previewText]",
        values.translations.hy.previewText
      );
      formData.append("translations[hy][markedText]", markedTextHy);
        const response = await axios({
          method: "POST",
          baseURL: "https://api.cccs.am/api/v1/news",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: formData,
        });
        history.push("/#/news");
        window.location.reload();
    },
  });
  React.useEffect(() => {
    for (let key in checkboxValues) {
      if (checkboxValues[key]) {
        if (!formik.values.categories.includes(`${key}`)) {
          formik.values.categories.push(`${key}`);
        }
      } else if (formik.values.categories.includes(`${key}`)) {
        formik.values.categories = formik.values.categories.filter(
          (item) => item !== `${key}`
        );
      }
    }
  }, [
    checkboxValues["news"],
    checkboxValues["cccs-in-media"],
    checkboxValues["cccs-media"],
    checkboxValues["cccs-media-collaboration"],
    checkboxValues["sources-of-armenian-diplomacy"],
    checkboxValues["policy-paper"],
    checkboxValues["monographs"],
    checkboxValues["annual-reports"],
  ]);
  const handleChekboxChange = (value) => {
    switch (value) {
      case "news":
        if (!checkboxValues["news"]) {
          setCheckboxValues({ ...checkboxValues, news: true });
        } else {
          setCheckboxValues({ ...checkboxValues, news: false });
        }
        break;
      case "cccs-in-media":
        if (!checkboxValues["cccs-in-media"]) {
          setCheckboxValues({ ...checkboxValues, "cccs-in-media": true });
        } else {
          setCheckboxValues({ ...checkboxValues, "cccs-in-media": false });
        }
        break;
      case "cccs-media":
        if (!checkboxValues["cccs-media"]) {
          setCheckboxValues({ ...checkboxValues, "cccs-media": true });
        } else {
          setCheckboxValues({ ...checkboxValues, "cccs-media": false });
        }
        break;
      case "cccs-media-collaboration":
        if (!checkboxValues["cccs-media-collaboration"]) {
          setCheckboxValues({
            ...checkboxValues,
            "cccs-media-collaboration": true,
          });
        } else {
          setCheckboxValues({
            ...checkboxValues,
            "cccs-media-collaboration": false,
          });
        }
        break;
      case "policy-paper":
        if (!checkboxValues["policy-paper"]) {
          setCheckboxValues({ ...checkboxValues, "policy-paper": true });
        } else {
          setCheckboxValues({ ...checkboxValues, "policy-paper": false });
        }
        break;
      case "sources-of-armenian-diplomacy":
        if (!checkboxValues["sources-of-armenian-diplomacy"]) {
          setCheckboxValues({
            ...checkboxValues,
            "sources-of-armenian-diplomacy": true,
          });
        } else {
          setCheckboxValues({
            ...checkboxValues,
            "sources-of-armenian-diplomacy": false,
          });
        }
        break;
      case "monographs":
        if (!checkboxValues["monographs"]) {
          setCheckboxValues({ ...checkboxValues, monographs: true });
        } else {
          setCheckboxValues({ ...checkboxValues, monographs: false });
        }
        break;
      case "annual-reports":
        if (!checkboxValues["annual-reports"]) {
          setCheckboxValues({ ...checkboxValues, "annual-reports": true });
        } else {
          setCheckboxValues({ ...checkboxValues, "annual-reports": false });
        }
        break;
    }
  };
  const updateMarkedTextHy = (editorData) => {
    const data = editorData.getData();
    setMarkedTextHy(data);
  };
  const updateMarkedTextEn = (editorData) => {
    const data = editorData.getData();
    setMarkedTextEn(data);
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.formWrapper}>
        <div className={styles.formContainer}>
          <input
            accept="image/*"
            type="file"
            name="mainImage"
            className={classes.input}
            onChange={(e) => {
              setPreviewImageUrls({
                ...previewImageUrls,
                image: URL.createObjectURL(e.target.files[0]),
              });
              formik.setFieldValue("mainImage", e.currentTarget.files[0]);
            }}
            id="upload-main-image"
          />
          <label htmlFor="upload-main-image">
            <div className={styles.inputAndPreviewContainer}>
              <div className={styles.mainImageInputContainer}>
                <Button
                  variant="outlined"
                  component="span"
                  style={{
                    width: "200px",
                    backgroundColor: "#00BCD7",
                    color: "white",
                    fontWeight: "700",
                  }}
                >
                  Upload main image
                </Button>
                <IconButton style={{ color: "#00BCD7" }} component="span">
                  <PhotoCamera />
                </IconButton>
              </div>
              <img src={previewImageUrls.image} style={{ maxWidth: "200px" }} />
            </div>
          </label>
          {formik.errors.mainImage ? (
            <div className={styles.errorContainer}>
              {formik.errors.mainImage}
            </div>
          ) : null}
          <input
            accept="application/pdf"
            type="file"
            name="pdfFile"
            className={classes.input}
            onChange={(e) => {
              // //console.log(e.currentTarget.files[0]);
              setPreviewImageUrls({
                ...previewImageUrls,
                pdfFilename: e.currentTarget.files[0]?.name,
              });
              formik.setFieldValue("pdfFile", e.currentTarget.files[0]);
            }}
            id="upload-pdf-file"
          />
          <label htmlFor="upload-pdf-file">
            <div className={styles.mainImageInputContainer}>
              <Button
                variant="outlined"
                component="span"
                style={{
                  width: "200px",
                  backgroundColor: "#00BCD7",
                  color: "white",
                  fontWeight: "700",
                }}
              >
                Upload pdf file
              </Button>
              <IconButton style={{ color: "#00BCD7" }} component="span">
                <PictureAsPdfIcon />
              </IconButton>
            </div>
            <p>{previewImageUrls.pdfFilename}</p>
          </label>
          <div className={styles.categoriesContainer}>
            <p style={{ fontWeight: 500, fontSize: "22px" }}>Categories</p>
            <FormGroup row style={{ marginBottom: "30px" }}>
              <div className={styles.categoriesBox}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => handleChekboxChange("news")}
                      style={{ color: "#00BDC7" }}
                    />
                  }
                  label="News"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => handleChekboxChange("cccs-in-media")}
                      style={{ color: "#00BDC7" }}
                    />
                  }
                  label="CCCS in media"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => handleChekboxChange("cccs-media")}
                      style={{ color: "#00BDC7" }}
                    />
                  }
                  label="CCCS media"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() =>
                        handleChekboxChange("cccs-media-collaboration")
                      }
                      style={{ color: "#00BDC7" }}
                    />
                  }
                  label="CCCS media collaboration"
                />
              </div>
              <div className={styles.categoriesBox}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => handleChekboxChange("policy-paper")}
                      style={{ color: "#00BDC7" }}
                    />
                  }
                  label="Policy paper"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() =>
                        handleChekboxChange("sources-of-armenian-diplomacy")
                      }
                      style={{ color: "#00BDC7" }}
                    />
                  }
                  label="Sources of armenian diplomacy"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => handleChekboxChange("monographs")}
                      style={{ color: "#00BDC7" }}
                    />
                  }
                  label="Monographs"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => handleChekboxChange("annual-reports")}
                      style={{ color: "#00BDC7" }}
                    />
                  }
                  label="Annual reports"
                />
              </div>
            </FormGroup>
            {formik.errors.categories ? (
              <div className={styles.errorContainer}>
                {formik.errors.categories}
              </div>
            ) : null}
          </div>
          <AppBar position="static" style={{ backgroundColor: "#00BCD7" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="wrapped label tabs example"
            >
              <Tab value="one" label="Armenian" wrapped {...a11yProps("one")} />
              <Tab value="two" label="English" {...a11yProps("two")} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index="one">
            <div className={styles.translationContainer}>
              <div>
                <MaterialTextField
                  style={{ marginBottom: "15px", width: "100%" }}
                  id="titleHy"
                  variant="outlined"
                  label="Title"
                  name="translations.hy.title"
                  value={formik.values.translations.hy.title}
                  onChange={formik.handleChange}
                />
                {formik.errors.titleHy ? (
                  <div className={styles.errorContainer}>
                    {formik.errors.titleHy}
                  </div>
                ) : null}
              </div>
              <div>
                <MaterialTextField
                  style={{ marginBottom: "15px", width: "100%" }}
                  id="previewTextHy"
                  variant="outlined"
                  label="Preview text"
                  name="translations.hy.previewText"
                  value={formik.values.translations.hy.previewText}
                  onChange={formik.handleChange}
                />
                {formik.errors.previewTextHy ? (
                  <div className={styles.errorContainer}>
                    {formik.errors.previewTextHy}
                  </div>
                ) : null}
              </div>
              <Editor
                updateOnChange={updateMarkedTextHy}
                markedData={markedTextHy}
              ></Editor>
            </div>
          </TabPanel>
          <TabPanel value={value} index="two">
            <div className={styles.translationContainer}>
              <div>
                <MaterialTextField
                  style={{ marginBottom: "15px", width: "100%" }}
                  id="titleEn"
                  variant="outlined"
                  label="Title"
                  name="translations.en.title"
                  value={formik.values.translations.en.title}
                  onChange={formik.handleChange}
                />
                {formik.errors.titleEn ? (
                  <div className={styles.errorContainer}>
                    {formik.errors.titleEn}
                  </div>
                ) : null}
              </div>
              <div>
                <MaterialTextField
                  style={{ marginBottom: "15px", width: "100%" }}
                  id="previewTextEn"
                  variant="outlined"
                  label="Preview text"
                  name="translations.en.previewText"
                  value={formik.values.translations.en.previewText}
                  onChange={formik.handleChange}
                />
                {formik.errors.previewTextEn ? (
                  <div className={styles.errorContainer}>
                    {formik.errors.previewTextEn}
                  </div>
                ) : null}
              </div>
              <Editor
                updateOnChange={updateMarkedTextEn}
                markedData={markedTextEn}
              ></Editor>
            </div>
          </TabPanel>

          <div className={styles.buttonContainer}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              style={{ backgroundColor: "#00BCD4", width: "15%" }}
            >
              Create
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};
export default NewsCreateForm;
