import * as React from "react";
import {
  List,
  Filter,
  Edit,
  Create,
  Datagrid,
  TextField,
  EditButton,
  FunctionField,
  TextInput,
} from "admin-on-rest";
import NewsCreateForm from "./NewsCreateForm";

import NewsUpdateForm from "./NewsUpdateForm";

const NewsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" search="title" source="title" alwaysOn />
  </Filter>
);

const NewsTitle = ({ record }) => {
  return <span>News {record ? `${record.id}` : ""}</span>;
};

export const NewsEdit = (props) => {
  return (
    <Edit {...props} title={<NewsTitle />}>
      <NewsUpdateForm props={props}></NewsUpdateForm>
    </Edit>
  );
};

export const NewsCreate = (props) => {
  return (
    <Create {...props}>
      <NewsCreateForm></NewsCreateForm>
    </Create>
  );
};
export const NewsList = (props) => (
  <List {...props} perPage={5} filters={<NewsFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="translations.hy.title" label="Title" />
      <TextField source="translations.hy.previewText" label="preview text" />
      <FunctionField
        label="main image"
        render={(record) => (
          <img src={record.mainImage} style={{ width: 100 }} />
        )}
      />
      <FunctionField
        label="categories"
        render={(record) => {
          let result = record.categories.join(", ");
          return result;
        }}
      />
      <EditButton />
    </Datagrid>
  </List>
);
