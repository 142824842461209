import * as React from "react";
import { useFormik } from "formik";
import axios from "axios";
import { useHistory } from "react-router-dom";
import styles from "./analyticalBulletin.module.css";
import MaterialTextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Edit } from "admin-on-rest";

const validate = (values) => {
    const errors = {};
  
    if (!values.imageURL) {
      errors.imageURL = "image is required!";
    }
  
    return errors;
  };

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: "none",
  },
}));


function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const BulletinUpdate = (props) => {
  const [previewImageUrls, setPreviewImageUrls] = React.useState({});
  const [value, setValue] = React.useState("one");
  const classes = useStyles();
  const [initialData, setInitialData] = React.useState({});

  React.useEffect(async () => {
    let response = await axios({
      method: "GET",
      baseURL: `https://api.cccs.am/api/v1/bulletin/${props.match.params.id}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    setInitialData(response.data);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const history = useHistory({
    forceRefresh: true,
  });
  const formData = new FormData();
  const formik = useFormik({
    initialValues: {
        image: "",
        linkTo: null,
      },
    validateOnChange: false,
    validateOnBlur: false,
    // validate,
    onSubmit: async (values) => {
      if (values.image) {
        formData.append("image", values.image);
      } else {
        formData.append("image", initialData.imageURL);
      }
      if (values.linkTo) {
        formData.append("linkTo", values.linkTo);
      } else {
        formData.append("linkTo", initialData.linkTo);
      }
 
      for (var pair of formData.entries()) {
        //console.log(pair[0] + " - " + pair[1]);
      }
      const response = await axios({
        method: "PATCH",
        baseURL: `https://api.cccs.am/api/v1/bulletin/${props.match.params.id}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
      });
      history.push("/#/bulletin");
      window.location.reload();
    },
  });

  return (
    <Edit {...props}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.formContainer}>
          <div className={styles.imageAndMailContainer}>
            <input
              accept="image/*"
              type="file"
              name="image"
              className={classes.input}
              onChange={(e) => {
                setPreviewImageUrls({
                  ...previewImageUrls,
                  imageURL: URL.createObjectURL(e.target.files[0]),
                });
                formik.setFieldValue("image", e.currentTarget.files[0]);
              }}
              id="upload-main-image"
            />
            <label htmlFor="upload-main-image">
              <div className={styles.inputAndPreviewContainer}>
                <div className={styles.mainImageInputContainer}>
                  <Button
                    variant="outlined"
                    component="span"
                    style={{
                      width: "300px",
                      backgroundColor: "#00BCD7",
                      color: "white",
                      fontWeight: "700",
                    }}
                  >
                    Upload bulletin image
                  </Button>
                  <IconButton style={{ color: "#00BCD7" }} component="span">
                    <PhotoCamera />
                  </IconButton>
                </div>
                <img
                  src={
                    previewImageUrls.imageURL
                      ? previewImageUrls.imageURL
                      : initialData.imageURL
                  }
                  style={{ maxWidth: "200px" }}
                />
              </div>
            </label>
            {formik.errors.image ? (
              <div className={styles.errorContainer}>{formik.errors.image}</div>
            ) : null}
            <MaterialTextField
              style={{ marginBottom: "15px", width: "100%" }}
              id="linkTo"
              variant="outlined"
              label="linkTo"
              name="linkTo"
              focused
              value={
                formik.values.linkTo !== null ? formik.values.linkTo : initialData.linkTo
              }
              onChange={formik.handleChange}
            />
            {formik.errors.linkTo ? (
              <div className={styles.errorContainer}>{formik.errors.linkTo}</div>
            ) : null}
          </div>
          
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            style={{ backgroundColor: "#00BCD4", width: "15%" }}
          >
            Update
          </Button>
        </div>
      </form>
    </Edit>
  );
};

export default BulletinUpdate;