import * as React from "react";
import { useFormik } from "formik";
import axios from "axios";
import { useHistory } from "react-router-dom";
import styles from "./projects.module.css";

// ------------------------------------------------------------------------------------
// import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MaterialTextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Editor from "./editor";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: "none",
  },
}));

const validate = (values) => {
  const errors = {};

  if (!values.mainImage) {
    errors.mainImage = "Main image is required!";
  }
  if (values.categorie.length === 0) {
    errors.categorie = "You must choose at least one type!";
  }
  if (!values.translations.en.title) {
    errors.titleEn = "Title is required!";
  }
  if (!values.translations.hy.title) {
    errors.titleHy = "Title is required!";
  }
  if (!values.translations.en.previewText) {
    errors.previewTextEn = "Preview text is required!";
  }
  if (!values.translations.hy.previewText) {
    errors.previewTextHy = "Preview text is required!";
  }
  return errors;
};

const ProjectsCreateForm = () => {
  let token = localStorage.getItem("token");
  const history = useHistory({
    forceRefresh: true,
  });
  const [previewImageUrls, setPreviewImageUrls] = React.useState({});
  const classes = useStyles();
  const [markedTextHy, setMarkedTextHy] = React.useState("");
  const [markedTextEn, setMarkedTextEn] = React.useState("");
  const [value, setValue] = React.useState("one");
  const [checkboxValue, setCheckboxValue] = React.useState("");
  const [sliderImages, setSliderImages] = React.useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const formData = new FormData();
  const formik = useFormik({
    initialValues: {
      mainImage: "",
      categorie: "",
      sliderImages: [],
      translations: {
        hy: {
          title: "",
          previewText: "",
          markedText: "",
        },
        en: {
          title: "",
          previewText: "",
          markedText: "",
        },
      },
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate,
    onSubmit: async (values) => {
      let categorie = "";
      if (checkboxValue) {
        categorie = checkboxValue;
      }
      formData.append("mainImage", values.mainImage);
      for (let image of values.sliderImages) {
        formData.append("sliderImages", image);
      }
      formData.append("categorie", categorie);
      formData.append("translations[en][title]", values.translations.en.title);
      formData.append(
        "translations[en][previewText]",
        values.translations.en.previewText
      );
      formData.append("translations[en][markedText]", markedTextEn);
      formData.append("translations[hy][title]", values.translations.hy.title);
      formData.append(
        "translations[hy][previewText]",
        values.translations.hy.previewText
      );
      formData.append("translations[hy][markedText]", markedTextHy);

      //console.log(values.sliderImages)
      for (let pairs of formData.entries()) {
        //console.log("asdasd")
        //console.log(pairs[0] + ', ' + pairs[1])
      }
      const response = await axios({
        method: "POST",
        baseURL: "https://api.cccs.am/api/v1/projects",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${token}`,
        },
        data: formData,
      }).catch((err) => {
        //console.log(err);
        // alert(err);
        history.push("/#/login");
        window.location.reload();
      });
      history.push("/#/projects");
      window.location.reload();
    },
  });
  React.useEffect(() => {
    formik.values.categorie = checkboxValue;
  }, [checkboxValue]);
  const handleChekboxChange = (value) => {
    switch (value) {
      case "AdaptationMechanism":
        setCheckboxValue("AdaptationMechanism");
        break;
      case "ThreeSeasSystem":
        setCheckboxValue("ThreeSeasSystem");
        break;
      case "Road":
        setCheckboxValue("Road");
        break;
      case "DigitalArmenia":
        setCheckboxValue("DigitalArmenia");
        break;
      case "HmgmAndCreativeGames":
        setCheckboxValue("HmgmAndCreativeGames");
        break;
      default:
        break;
    }
  };
  const updateMarkedTextHy = (editorData) => {
    const data = editorData.getData();
    setMarkedTextHy(data);
  };
  const updateMarkedTextEn = (editorData) => {
    const data = editorData.getData();
    setMarkedTextEn(data);
  };

  React.useEffect(() => {
    for (let i = 0; i < sliderImages.length; i++) {
      formik.values.sliderImages.push(sliderImages[i]);
    }
    //console.log(formik);
  }, [sliderImages]);
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.formWrapper}>
        <div className={styles.formContainer}>
          <input
            accept="image/*"
            type="file"
            name="mainImage"
            className={classes.input}
            onChange={(e) => {
              setPreviewImageUrls({
                ...previewImageUrls,
                image: URL.createObjectURL(e.target.files[0]),
              });
              formik.setFieldValue("mainImage", e.currentTarget.files[0]);
            }}
            id="upload-main-image"
          />
          <label htmlFor="upload-main-image">
            <div className={styles.inputAndPreviewContainer}>
              <div className={styles.mainImageInputContainer}>
                <Button
                  variant="outlined"
                  component="span"
                  style={{
                    width: "200px",
                    backgroundColor: "#00BCD7",
                    color: "white",
                    fontWeight: "700",
                  }}
                >
                  Upload main image
                </Button>
                <IconButton style={{ color: "#00BCD7" }} component="span">
                  <PhotoCamera />
                </IconButton>
              </div>
              <img src={previewImageUrls.image} style={{ maxWidth: "200px" }} />
            </div>
          </label>
          {formik.errors.mainImage ? (
            <div className={styles.errorContainer}>
              {formik.errors.mainImage}
            </div>
          ) : null}
          <input
            accept="image/*"
            multiple
            type="file"
            name="sliderImages"
            className={classes.input}
            onChange={(e) => {
              setSliderImages(() => {
                let sliderImagesArray = [];
                for (let i = 0; i < e.currentTarget.files.length; i++) {
                  sliderImagesArray.push(e.currentTarget.files[i]);
                }
                return sliderImagesArray;
              });
              //console.log(formik.values);
            }}
            id="upload-slider-images"
          />
          <label htmlFor="upload-slider-images">
            <div className={styles.inputAndPreviewContainer}>
              <div className={styles.mainImageInputContainer}>
                <Button
                  variant="outlined"
                  component="span"
                  style={{
                    width: "200px",
                    backgroundColor: "#00BCD7",
                    color: "white",
                    fontWeight: "700",
                  }}
                >
                  Upload slider images
                </Button>
                <IconButton style={{ color: "#00BCD7" }} component="span">
                  <PhotoCamera />
                </IconButton>
              </div>
              {sliderImages.map((sliderImage) => (
                <img
                  src={URL.createObjectURL(sliderImage)}
                  style={{ maxWidth: "200px", marginLeft: 5 }}
                />
              ))}
            </div>
          </label>
          {formik.errors.mainImage ? (
            <div className={styles.errorContainer}>
              {formik.errors.mainImage}
            </div>
          ) : null}
          <div className={styles.categoriesContainer}>
            <p style={{ fontWeight: 500, fontSize: "22px" }}>Categories</p>
            <RadioGroup row style={{ marginBottom: "30px" }}>
              <div className={styles.categoriesBox}>
                <FormControlLabel
                  control={
                    <Radio
                      value="AdaptationMechanism"
                      onChange={() =>
                        handleChekboxChange("AdaptationMechanism")
                      }
                      style={{ color: "#00BDC7" }}
                    />
                  }
                  label="AdaptationMechanism"
                />
                <FormControlLabel
                  control={
                    <Radio
                      value="ThreeSeasSystem"
                      onChange={() => handleChekboxChange("ThreeSeasSystem")}
                      style={{ color: "#00BDC7" }}
                    />
                  }
                  label="ThreeSeasSystem"
                />
                <FormControlLabel
                  control={
                    <Radio
                      value="Road"
                      onChange={() => handleChekboxChange("Road")}
                      style={{ color: "#00BDC7" }}
                    />
                  }
                  label="Road"
                />
                <FormControlLabel
                  control={
                    <Radio
                      value="DigitalArmenia"
                      onChange={() => handleChekboxChange("DigitalArmenia")}
                      style={{ color: "#00BDC7" }}
                    />
                  }
                  label="DigitalArmenia"
                />
              </div>
              <div className={styles.categoriesBox}>
                <FormControlLabel
                  control={
                    <Radio
                      value="HmgmAndCreativeGames"
                      onChange={() =>
                        handleChekboxChange("HmgmAndCreativeGames")
                      }
                      style={{ color: "#00BDC7" }}
                    />
                  }
                  label="HmgmAndCreativeGames"
                />
              </div>
            </RadioGroup>
            {formik.errors.categorie ? (
              <div className={styles.errorContainer}>
                {formik.errors.categorie}
              </div>
            ) : null}
          </div>
          <AppBar position="static" style={{ backgroundColor: "#00BCD7" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="wrapped label tabs example"
            >
              <Tab value="one" label="Armenian" wrapped {...a11yProps("one")} />
              <Tab value="two" label="English" {...a11yProps("two")} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index="one">
            <div className={styles.translationContainer}>
              <div>
                <MaterialTextField
                  style={{ marginBottom: "15px", width: "100%" }}
                  id="titleHy"
                  variant="outlined"
                  label="Title"
                  name="translations.hy.title"
                  value={formik.values.translations.hy.title}
                  onChange={formik.handleChange}
                />
                {formik.errors.titleHy ? (
                  <div className={styles.errorContainer}>
                    {formik.errors.titleHy}
                  </div>
                ) : null}
              </div>
              <div>
                <MaterialTextField
                  style={{ marginBottom: "15px", width: "100%" }}
                  id="previewTextHy"
                  variant="outlined"
                  label="Preview text"
                  name="translations.hy.previewText"
                  value={formik.values.translations.hy.previewText}
                  onChange={formik.handleChange}
                />
                {formik.errors.previewTextHy ? (
                  <div className={styles.errorContainer}>
                    {formik.errors.previewTextHy}
                  </div>
                ) : null}
              </div>
              <Editor
                updateOnChange={updateMarkedTextHy}
                markedData={markedTextHy}
              ></Editor>
            </div>
          </TabPanel>
          <TabPanel value={value} index="two">
            <div className={styles.translationContainer}>
              <div>
                <MaterialTextField
                  style={{ marginBottom: "15px", width: "100%" }}
                  id="titleEn"
                  variant="outlined"
                  label="Title"
                  name="translations.en.title"
                  value={formik.values.translations.en.title}
                  onChange={formik.handleChange}
                />
                {formik.errors.titleEn ? (
                  <div className={styles.errorContainer}>
                    {formik.errors.titleEn}
                  </div>
                ) : null}
              </div>
              <div>
                <MaterialTextField
                  style={{ marginBottom: "15px", width: "100%" }}
                  id="previewTextEn"
                  variant="outlined"
                  label="Preview text"
                  name="translations.en.previewText"
                  value={formik.values.translations.en.previewText}
                  onChange={formik.handleChange}
                />
                {formik.errors.previewTextEn ? (
                  <div className={styles.errorContainer}>
                    {formik.errors.previewTextEn}
                  </div>
                ) : null}
              </div>
              <Editor
                updateOnChange={updateMarkedTextEn}
                markedData={markedTextEn}
              ></Editor>
            </div>
          </TabPanel>

          <div className={styles.buttonContainer}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              style={{ backgroundColor: "#00BCD4", width: "15%" }}
            >
              Create
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};
export default ProjectsCreateForm;
